<template>
  <v-dialog v-model="innerValue" hide-overlay transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-card-title class="grey lighten-4">
        <app-id-icon />
        <span class="font-weight-bold tertiary--text title">{{ $t("time.calendar.shift_detail") }}</span>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="grey lighten-1" icon @click="handleCloseClick">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("buttons.close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <month-picker v-model="selectedDate" />
            </v-flex>
            <v-flex xs12>
              <div class="grid-container">
                <div class="grid-headers">
                  <div class="grid-header timeaxis-col">
                    <div class="grid-header-row grid-header-row-top">
                      <div class="timeaxis-cell">
                        {{ dateLabel }}
                      </div>
                    </div>
                    <div class="grid-header-row grid-header-row-bottom">
                      <div
                        :class="['timeaxis-cell', { weekday: item.isWeekend }]"
                        v-for="(item, index) in calendar"
                        :key="index"
                      >
                        <v-tooltip bottom v-if="item.holiday">
                          <template #activator="{on}">
                            <span
                              v-on="on"
                              :style="{
                                color: item.holiday.color,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0 1px'
                              }"
                            >
                              {{ item.holiday.name }}
                            </span>
                          </template>
                          {{ item.holiday.name }}
                        </v-tooltip>
                        <span v-else> {{ item.date }} {{ item.day }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-body">
                  <div class="grid-row">
                    <div class="grid-row-col">
                      <div
                        :class="['grid-cell timeaxis-cell', { 'empty-cell': !workplan }]"
                        v-for="(workplan, index) in events"
                        :key="index"
                      >
                        <timeaxis-item v-if="workplan" :workplan="workplan" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <shift-detail-dialog
      v-model="detailDialogStatus"
      :shift="currentShift"
      :workplan="currentWorkplan"
      @close="onDetailDialogClose"
    />
  </v-dialog>
</template>

<script>
  export default {
    name: "WorkplanRequestCalendar",
    components: {
      TimeaxisItem: () => import("./TimeaxisItem"),
      ShiftDetailDialog: () => import("./ShiftDetailDialog")
    },
    props: { value: Boolean, item: Object },
    data: vm => ({
      calendar: [],
      events: [],
      selectedDate: null,
      currentShift: null,
      currentWorkplan: null,
      detailDialogStatus: false
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      currentYear() {
        return this.selectedDate ? this.$moment(this.selectedDate).format("YYYY") : null;
      },
      currentMonth() {
        return this.selectedDate ? this.$moment(this.selectedDate).format("MM") : null;
      },
      dateLabel() {
        return this.currentYear && this.currentMonth
          ? `${this.$moment(this.selectedDate, "YYYY-MM").format("MMMM")} / ${this.currentYear}`
          : "";
      }
    },
    watch: {
      value(status) {
        if (status) {
          this.init();
        }
      }
    },
    methods: {
      handleDateInput(value) {
        this.initCalendar();
        this.buildEvents();
      },
      init() {
        if (this.item) {
          const workplan = this.item.workplanRequest.plannedWorkplans[0];
          if (workplan) {
            this.selectedDate = this.$moment(workplan.date).format("YYYY-MM");
            this.initCalendar();
            this.buildEvents();
          }
        }
      },
      initCalendar() {
        this.calendar = [];
        const daysInMonth = this.$moment(`${this.currentYear}-${this.currentMonth}`, "YYYY-MM").daysInMonth();
        for (let i of Array(daysInMonth).keys()) {
          const date = (i + 1).toString().padStart(2, "0");
          const currDate = this.$moment(`${this.currentYear}-${this.currentMonth}-${date}`).format("YYYY-MM-DD");
          this.calendar.push({
            day: this.$moment(currDate).format("ddd"),
            holiday: null,
            isWeekend: [0, 6].includes(this.$moment(currDate).day()),
            date
          });
        }
      },
      buildEvents() {
        this.events = [];
        this.calendar.forEach(item => {
          const itemDate = `${this.currentYear}-${this.currentMonth}-${item.date}`;
          const workplan = this.item.workplanRequest.plannedWorkplans.find(({ date }) => date === itemDate);
          this.events.push(workplan);
        });
      },
      handleCloseClick() {
        this.innerValue = false;
      },
      openShiftDetail(shift, workplan) {
        this.currentShift = shift;
        this.currentWorkplan = workplan;
        this.detailDialogStatus = true;
      },
      onDetailDialogClose() {
        this.currentShift = null;
        this.currentWorkplan = null;
      }
    },
    mounted() {
      this.$eventBus.$on("open:shift-detail", this.openShiftDetail);
    },
    beforeDestroy() {
      this.$eventBus.$off("open:shift-detail");
    }
  };
</script>

<style lang="scss" scoped>
  .grid-container {
    height: calc(100vh - 220px);
    overflow: auto;
    position: relative;
    border: 1px solid #ededed;
  }
  .grid-headers {
    min-width: 100%;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    line-height: normal;
    z-index: 2;
    position: sticky;
    top: 0;
    .grid-header {
      padding: 0;
      cursor: pointer;
      user-select: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      flex-shrink: 0;
      overflow: hidden;
      background: #f9f9f9;
      color: #565656;
      outline: none;
      text-transform: none;
      border-bottom: 1px solid #e0e0e0;
      &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }
      &-text {
        border-bottom: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        user-select: none;
        padding: 1em 0;
        white-space: nowrap;
        font-weight: inherit;
        &-content {
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1 1 0;
          width: 0;
          font-size: 1em;
        }
      }
      &-row {
        display: flex;
        flex-direction: row;
        flex: 1;
        .timeaxis-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #565656;
          border-bottom: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          overflow: hidden;
          transition: background-color 0.2s;
          font-size: 1em;
          font-weight: inherit;
          padding: 0.5em 0;
          white-space: nowrap;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &:last-child {
            border-right: none;
          }
        }
        &-top {
          .timeaxis-cell {
            width: 100%;
            height: 35px;
          }
        }
        &-bottom {
          .timeaxis-cell {
            width: 60px;
            height: 35px;
            border-bottom: none;
          }
          .timeaxis-cell.weekday {
            background-color: #f7efef;
          }
        }
      }
    }
  }

  .grid-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    .grid-row {
      display: flex;
      .grid-row-col {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border-bottom: 1px solid #ededed;
        .grid-cell {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;
          font-weight: 300;
          padding: 12px;
          overflow: hidden;
          white-space: nowrap;
          flex-shrink: 0;
          text-overflow: ellipsis;
          &:not(:last-child) {
            border-right: 1px solid #ededed;
          }
        }
        .employee-cell {
          width: 240px;
          justify-content: flex-start;
        }
        .total-hour-cell {
          width: 160px;
          justify-content: flex-start;
        }
        .timeaxis-cell {
          width: 60px;
          align-items: flex-start;
        }
        .empty-cell {
          align-items: center;
        }
      }
    }
  }
</style>
